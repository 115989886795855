//RUTAS DE VIAJES
//--gam
export const urlGetTrips = "/api/trip";
export const urlCreateTrips = "/api/trip";
export const urlEditTrips = "/api/trip";
//--tipo tarifa
export const urlGetFareTypes = "/api/fare-types";
//--tipo viajes
export const urlGetTripTypes = "/api/trip-types";
//--viajes distancia
export const urlCreateDistance = "/api/distance/places";
export const urlEditDistance = "/api/distance/places";
//--Crear equipo v3
export const urlLinkTripTeamV3 = "/api/v3/viaje/equipo";
//--equipos momentaneos v2
export const urlGetTeamTrip = "/api/v2/trip/vehicle";
export const urlLinkTripTeamV2 = "/api/v2/trip/link-vehicle";
export const urlEndtripV2 = "/api/v2/trip/end";
export const urlRemoveTeam = "/api/v2/trip/remove-vehicle";
//--equipos pre-definidos v2
export const urlGetPredefinedTeams = "/api/equipos";
export const urlUpdateEquipos = "/api/vehicles-act";
export const urlCretaePredefinedTeams = "/api/vehicles-link-entities";
//--equipos rutas viejas
export const urlLinkTripTeam = "/api/trip/link-vehicle";
export const urlEndTrip = "api/trip/end";
//--kms larga distancia
export const urlGetKmsLongDist = "/api/trip/config/largadist";
export const urlPostKmsLongDist = "/api/trip/larga-dist";
export const urlHistoryLongDist = "/api/trip/config/largadisth";
//--listar toneladas viajes larga dist
export const urlGetTnsLargaDist = "/api/trip-tonsldist";
//--tarifas negociadas
export const urlGetTarifasNegociadas = "/api/tarifas-negociadas";
//--buscar tns SAF
export const urlGetTnsSAF = "/api/saf/buscar-tns";
//--buscar equipos SAF
export const urlGetEquiposSAF = "/api/saf/buscar-op-dif";
