import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import {
  urlGetPredefinedTeams,
  urlUpdateEquipos,
} from "../../../../../api/URLs/viajes";
//import { urlEditDriverEmpresa } from "../../../../../api/URLs/choferes";
//HOOKS
import { useModal } from "../../../../../hooks/useModal";
//FOMRS
import FormTeam from "./FormTeam";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../../components/Modals/ModalAlert";
//--Table
import Table from "../../../../../components/Tools/Tables/Table/Table";
//--Basic
import BoxContainer from "../../../../../components/Tools/Cards/BoxContainer/BoxContainer";
import CustomBtn from "../../../../../components/Tools/Basics/CustomBtn";

const FormSelectTeam = ({ trip, reFetch, setReFetch }) => {
  //useModal
  const { showModal, modalType, msjModal, handleClose, handleError } =
    useModal();
  //States
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState(null);
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [teamFormat, setTemaFormat] = useState(null);
  //GET
  const getTeams = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetPredefinedTeams);
      setTeams(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
      setSelectedTeam(null);
    }
  };
  const confirmTeam = () => {
    const merged = {
      id_chofer: selectedTeam.id_chofer,
      id_camion: selectedTeam.id_camion,
      id_acoplado: selectedTeam.id_acoplado,
    };
    setTemaFormat(merged);
    setSection("form");
  };
  const toggleActivoEquipo = async (equipo) => {
    try {
      setLoading(true);
      await axiosWithAuth.put(`${urlUpdateEquipos}`, {
        id_chofer: equipo.id_chofer,
        id_camion: equipo.id_camion,
        id_acoplado: equipo.id_acoplado,
      });
      await getTeams();
    } catch (error) {
      const auxError =
        error.response?.data?.msj || "Error al actualizar el estado";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //BOTONES
  const btnSelect = (team) => {
    return (
      <CustomBtn
        type={team === selectedTeam ? "link_success_table" : "link_table"}
        text={team === selectedTeam ? "Asignado" : "Asignar"}
        onClick={() => setSelectedTeam(team)}
      />
    );
  };
  //COLUMNAS
  const columns = [
    { header: "Camión", field: "patente_camion" },
    {
      header: "Propio",
      field: "camion_es_propio",
      formater: "boolean_color",
      sortable: true,
    },
    { header: "Batea", field: "patente_acoplado" },
    { header: "Chofer", field: "apelnomb_chofer" },
    { header: "Empresa", field: "empresa_chofer" },
    {
      header: "Activo Empresa",
      body: (driver) => (
        <CustomBtn
          //type={"link_table"}
          btnType={driver.activo_equipo ? "success" : "danger"}
          text={driver.activo_equipo ? "Activo" : "Inactivo"}
          onClick={() => toggleActivoEquipo(driver)}
        />
      ),
    },
    { body: btnSelect },
  ];
  const rowClass = (row) => {
    return !row.activo_equipo ? "bg-danger bg-opacity-25" : "";
  };
  useEffect(() => {
    getTeams();
  }, []);
  return (
    <div>
      <section className="py-1 d-md-flex">
        <h4>Conformar Equipo</h4>
        <div className="ms-auto">
          <CustomBtn
            onClick={() => confirmTeam()}
            btnType={`${selectedTeam ? "success" : "danger"} btn-sm`}
            text={"Confirmar Equipo"}
            disabled={!selectedTeam ? true : false}
          />
        </div>
      </section>
      <section>
        <BoxContainer>
          <div className="d-md-flex justify-content-evenly">
            <p>
              <strong>Camión: </strong>
              {selectedTeam ? selectedTeam.patente_camion : "-"}
            </p>
            <p>
              <strong>Batea: </strong>
              {selectedTeam ? selectedTeam.patente_acoplado : "-"}
            </p>
            <p>
              <strong>Chofer: </strong>
              {selectedTeam ? selectedTeam.apelnomb_chofer : "-"}
            </p>
          </div>
        </BoxContainer>
      </section>
      {!section ? (
        <BoxContainer minSize={"md"} maxSize={"md"}>
          <Table
            data={teams}
            columns={columns}
            title={"Equipos"}
            textFilter
            loading={loading}
            scrollSize={"md"}
            rowClass={rowClass}
          />
        </BoxContainer>
      ) : (
        <FormTeam
          trip={trip}
          team={teamFormat}
          reFetch={reFetch}
          setReFetch={setReFetch}
        />
      )}
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default FormSelectTeam;
