//RUTAS CHOFERES
//--fam
export const urlGetDrivers = "/api/driver";
export const urlCreateDrivers = "/api/driver";
export const urlEditDrivers = "/api/driver";
export const urlEditDriverEmpresa = "/api/driver/active";
//--crear movimiento
export const urlCreateDriverCashMovement = "/api/driver/cash/mov";
//--movimientos x chofer
export const urlGetDriverCashMovements = "/api/driver/cash/movs";
//--categorias de movimientos
export const urlGetCatMovements = "/api/driver/cat/movs";
