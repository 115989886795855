import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import {
  urlGetDrivers,
  urlEditDriverEmpresa,
} from "../../../../../api/URLs/choferes";
import {
  urlGetTrucks,
  urlGetTrailers,
} from "../../../../../api/URLs/vehiculos";
//HOOKS
import { useModal } from "../../../../../hooks/useModal";
//FORMS
import FormTeam from "./FormTeam";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../../components/Modals/ModalAlert";
//--Table
import Table from "../../../../../components/Tools/Tables/Table/Table";
//--Basic
import BoxContainer from "../../../../../components/Tools/Cards/BoxContainer/BoxContainer";
import CustomBtn from "../../../../../components/Tools/Basics/CustomBtn";

const FormConformTeam = ({ trip, reFetch, setReFetch }) => {
  //useModal
  const { showModal, modalType, msjModal, handleClose, handleError } =
    useModal();
  //States
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState(1);
  const [teamFormat, setTemaFormat] = useState(null);
  //--camiones
  const [trucks, setTrucks] = useState([]);
  const [selectedTruck, setSelectedTruck] = useState(null);
  //--acoplados
  const [trailers, setTrailers] = useState([]);
  const [selectedTrailer, setSelectedTrailer] = useState(null);
  //--choferes
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  //GET camiones - acoplados - choferes
  const getParallel = async () => {
    try {
      setLoading(true);
      const promise_array = [];
      promise_array.push(await axiosWithAuth.get(urlGetDrivers));
      promise_array.push(await axiosWithAuth.get(urlGetTrucks));
      promise_array.push(await axiosWithAuth.get(urlGetTrailers));
      const api_responses = await Promise.all(promise_array);
      const [drivers_response, trucks_response, trailers_response] =
        api_responses;
      setDrivers(drivers_response.data);
      setTrucks(trucks_response.data);
      setTrailers(trailers_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };

  const toggleActivoEmpresa = async (driver) => {
    try {
      setLoading(true);
      await axiosWithAuth.put(`${urlEditDriverEmpresa}`, {
        id_chofer: driver.id_chofer,
      });
      await getParallel();
    } catch (error) {
      const auxError =
        error.response?.data?.msj || "Error al actualizar el estado";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  const confirmTeam = () => {
    const merged = {
      id_chofer: selectedDriver.id_chofer,
      id_camion: selectedTruck.id,
      id_acoplado: selectedTrailer.id,
    };
    setTemaFormat(merged);
    setSection(4);
  };
  const handleSelect = (p) => {
    section === 1
      ? setSelectedTruck(p)
      : section === 2
      ? setSelectedTrailer(p)
      : setSelectedDriver(p);
  };
  const btnSelect = (v) => {
    return (
      <CustomBtn
        type={"link_table"}
        text={"Asignar"}
        onClick={() => handleSelect(v)}
      />
    );
  };
  //COLUMNS
  //--Trucks
  const columnsVehicles = [
    { header: "Patente", field: "patente" },
    {
      header: "Prop.",
      field: "es_propio",
      formater: "boolean_color",
      sortable: true,
    },
    { header: "Movil", field: "numMovil" },
    { body: btnSelect },
  ];
  //--Drivers
  const columnsDrivers = [
    { header: "Nombre", field: "apelnomb" },
    { header: "Empresa", field: "nomb_empresa" },
    { body: btnSelect },
  ];
  useEffect(() => {
    getParallel();
  }, []);
  return (
    <main>
      <section className="py-1 d-md-flex">
        <h4>Conformar Equipo</h4>
        <div className="ms-auto">
          {section === 4 ? null : (
            <>
              <CustomBtn
                onClick={() => setSection(1)}
                btnType={`${
                  section === 1 ? "success" : "outline-success"
                } btn-sm me-1`}
                text={"Camión"}
              />
              <CustomBtn
                onClick={() => setSection(2)}
                btnType={`${
                  section === 2 ? "success" : "outline-success"
                } btn-sm me-1`}
                text={"Batea"}
              />
              <CustomBtn
                onClick={() => setSection(3)}
                btnType={`${
                  section === 3 ? "success" : "outline-success"
                } btn-sm me-1`}
                text={"Chofer"}
              />
            </>
          )}
          <CustomBtn
            onClick={() => confirmTeam()}
            btnType={`${
              !selectedTruck || !selectedTrailer || !selectedDriver
                ? "danger"
                : "success"
            } btn-sm`}
            text={"Confirmar Equipo"}
            disabled={
              !selectedTruck || !selectedTrailer || !selectedDriver
                ? true
                : false
            }
          />
        </div>
      </section>
      <section>
        <BoxContainer>
          <div className="d-md-flex justify-content-evenly">
            <p>
              <strong>Camión: </strong>
              {selectedTruck ? selectedTruck.patente : "-"}
            </p>
            <p>
              <strong>Batea: </strong>
              {selectedTrailer ? selectedTrailer.patente : "-"}
            </p>
            <p>
              <strong>Chofer: </strong>
              {selectedDriver ? selectedDriver.apelnomb : "-"}
            </p>
          </div>
        </BoxContainer>
      </section>
      <section className="mt-3">
        {section === 4 ? (
          <FormTeam
            trip={trip}
            team={teamFormat}
            reFetch={reFetch}
            setReFetch={setReFetch}
          />
        ) : (
          <BoxContainer minSize={"md"} maxSize={"md"}>
            <Table
              data={section === 1 ? trucks : section === 2 ? trailers : drivers}
              columns={section === 3 ? columnsDrivers : columnsVehicles}
              keyProp={section === 3 ? "id_chofer" : "id"}
              title={
                section === 1
                  ? "Camiones"
                  : section === 2
                  ? "Bateas"
                  : "Choferes"
              }
              textFilter
              loading={loading}
              scrollSize={"md"}
            />
          </BoxContainer>
        )}
      </section>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </main>
  );
};

export default FormConformTeam;
